var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',{style:({ height: '100%' })},[_c('a-layout-sider',{staticStyle:{"background":"#001529"},attrs:{"trigger":null,"collapsible":""},model:{value:(_vm.$store.state.collapsed),callback:function ($$v) {_vm.$set(_vm.$store.state, "collapsed", $$v)},expression:"$store.state.collapsed"}},[_c('Sidebar')],1),_c('a-layout',[_c('a-layout-header',{staticStyle:{"background":"#fff","padding":"0"}},[_c('Header')],1),_c('a-layout',{staticStyle:{"padding":"0 24px 24px"}},[_c('a-breadcrumb',{staticStyle:{"margin":"16px 0"},attrs:{"routes":_vm.breadcrumbList},scopedSlots:_vm._u([{key:"itemRender",fn:function(ref){
var route = ref.route;
var routes = ref.routes;
var paths = ref.paths;
return [(routes.indexOf(route) === routes.length - 1)?_c('span',[_vm._v(" "+_vm._s(_vm.labelMode == 'add' ? route.name : '編輯標籤')+" ")]):_c('span',[_vm._v(" "+_vm._s(route.name)+" ")])]}}])}),_c('a-layout-content',{style:({
          background: '#fff',
          padding: '24px',
          margin: 0,
          minHeight: '280px'
        })},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }